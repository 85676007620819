import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiRequestsService } from '../../services/api-requests.service';
import { EncrptDecreptService } from '../../services/encrpt-decrept.service';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-register-popup',
  templateUrl: './register-popup.component.html',
  styleUrls: ['./register-popup.component.scss']
})
export class RegisterPopupComponent implements OnInit {

  @Input() userName: string;
  @Input() userEmail: string;
  @Input() userContact: string;
  @Input() userCity: string;
  @Input() isUserLoggedIn: boolean;
  @Input() fromUserCreation: boolean;

  public registermodel: any = {
    nm: "",
    eml: "",
    mob: "",
    ct: "",
    pw: "",
    reg: ""
  }

  public isLoading = false;
  errorMsg: string = "";
  emailErr: string = "";
  disableBtnIfEmail: boolean = false;

  constructor(private authService: AuthService,private encryptService: EncrptDecreptService, private ngbActiveModal: NgbActiveModal, private api: ApiRequestsService) { }

  ngOnInit(): void {
    if (this.isUserLoggedIn) {
      this.registermodel.nm = this.userName;
      this.registermodel.eml = this.userEmail;
      this.registermodel.mob = this.userContact;
      this.registermodel.ct = this.userCity;
    }
  }



  registerUser() {
    if (!this.registermodel.nm || !this.registermodel.eml) {
      this.errorMsg = 'Enter note'; return;
    }

    const endpoint: string = 'user/registerUser';
    this.registermodel = JSON.stringify(this.registermodel);
    let data = this.encryptService.encrypt(this.registermodel);

    this.api.postApiCall(endpoint, { ox: data }).subscribe((res: any) => {
      if (res['errorCode'] == this.api.NO_ERROR) {
        this.closeModal({ status: "procced", data: this.registermodel, id: res['Data'] })
        this.errorMsg = res['errorDescription']
      }else{
        this.errorMsg = res['errorDescription']
      }
    }, (err: any) => {
      console.log(err);
    }, () => { })
  }

  checkUniqueEmail() {
    let endpoint = "user/CheckUniqueEmail";
    let params: any = { "email": this.registermodel.eml };
    params = JSON.stringify(params);
    let data = this.encryptService.encrypt(params);
    this.api.getApiCall(endpoint, { ox: data }).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        this.emailErr = "";
        this.disableBtnIfEmail = false;
      } else {
        this.emailErr = successResp['errorDescription'];
        this.disableBtnIfEmail = true;
      }
    }, err => { });
  }


  openModal() {
    document.getElementById('login-btn').click();
  }

  closeModal(data = null) {
    this.ngbActiveModal.close(data);
  }

}
