import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiRequestsService } from '../../services/api-requests.service';
import { EncrptDecreptService } from '../../services/encrpt-decrept.service';

@Component({
  selector: 'app-program-feedback',
  templateUrl: './program-feedback.component.html',
  styleUrls: ['./program-feedback.component.scss']
})
export class ProgramFeedbackComponent implements OnInit {

  @Input() programid: number = 0;
  @Input() userid: number = 0;
  @Input() programname: string = "";

  errorMsg: string = "";
  isLoading: boolean = false;

  model: any = {
    feedback: "",
    rating: 0
  }
  pname: string = "";

  constructor(private encryptService: EncrptDecreptService, private ngbActiveModal: NgbActiveModal, private api: ApiRequestsService) { }

  ngOnInit(): void {
  }

  ProgramFeedbackSubmitForm() {
    console.log(this.model);
    console.log(this.model);

    if (!this.model.feedback) {
      this.errorMsg = 'Enter feedback'; return;
    }

    const endpoint: string = 'program/insertProgramFeedback';
    let params: any = {
      "programid": this.programid,
      "userrating": +this.model.rating,
      "userfeedback": this.model.feedback,
    };
    params = JSON.stringify(params);
    let data = this.encryptService.encrypt(params);
    this.isLoading = true;


    this.api.postApiCall(endpoint, { ox: data }).subscribe((res: any) => {
      if (res['errorCode'] == this.api.NO_ERROR) {
        //     console.log(res);
        this.closeModal()
      }
      this.errorMsg = res['errorDescription']
      this.isLoading = false;
    }, (err: any) => {
      this.isLoading = false;
    }, () => { })


  }

  closeModal(isclose = true) {
    this.ngbActiveModal.close();
  }

}
