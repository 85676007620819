import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Notes } from '../../interfaces/user';
import { ApiRequestsService } from '../../services/api-requests.service';
import { EncrptDecreptService } from '../../services/encrpt-decrept.service';

declare var $: any;

@Component({
  selector: 'app-notes-popup',
  templateUrl: './notes-popup.component.html',
  styleUrls: ['./notes-popup.component.scss']
})
export class NotesPopupComponent implements OnInit {

  @Input() sessionid: number;
  @Input() programid: number;
  @Input() item: any = {};


  model: Notes = {
    sessionid: 0,
    notesdetails: ''
  };

  errorMsg: string = '';

  public isLoading = false;

  constructor(private encryptService: EncrptDecreptService, private api: ApiRequestsService, private ngbActiveModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.model.sessionid = this.sessionid;
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.registerDragElement();

  }

  closeModal(isclose = true) {
    this.ngbActiveModal.close();
  }

  insertNewUserNotes() {
    if (this.isLoading) return;
    if (!this.model.notesdetails || !this.model.sessionid) {
      this.errorMsg = 'Enter note'; return;
    }

    const endpoint: string = 'user/insertNewUserNotes';
    let params: any = { data: this.model };
    params = JSON.stringify(params);
    let data = this.encryptService.encrypt(params);

    this.isLoading = true;
    this.api.postApiCall(endpoint, { ox: data }).subscribe((res: any) => {
      if (res['errorCode'] == this.api.NO_ERROR) {
        // console.log(res);
        this.closeModal()
      }
      this.errorMsg = res['errorDescription']
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
    }, () => { })
  }

  registerDragElement() {
    $(document).ready(function () {
      let modalContent: any = $('.modal-dialog');
      let modalHeader = $('.modal-header');

      modalHeader.addClass('cursor-all-scroll');
      modalContent.draggable({
        handle: '#handeler',
        cancel: '.modal-body'
      });

      modalContent.resizable({
        minHeight: 550,
        minWidth: 500
      });

    });
  }
}
