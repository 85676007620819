import { Injectable, TemplateRef } from '@angular/core';
import { ToasterService, ToasterConfig } from 'angular2-toaster/angular2-toaster';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  // toasts: any[] = [];

  // private delay: 1000;
  // constructor() { }

  // private show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
  //   this.toasts.push({ textOrTpl, ...options });
  // }

  // remove(toast: any) {
  //   this.toasts = this.toasts.filter(t => t !== toast);
  // }

  // clear() {
  //   this.toasts.splice(0, this.toasts.length);
  // }

  // private showStandard(msg: string) {
  //   this.show(msg);
  // }

  // private showSuccess(msg: string) {
  //   this.show(msg, { classname: 'bg-success text-light', delay: this.delay });
  // }

  // private showWarning(msg: string) {
  //   this.show(msg, { classname: 'bg-warning text-light', delay: this.delay });
  // }

  // private showDanger(dangerTpl: string | TemplateRef<any>) {
  //   this.show(dangerTpl, { classname: 'bg-danger text-light', delay: this.delay });
  // }

  // public showTostMsg(type: 'error' | 'success' | 'warning', msg: string, heading: string='') {
  //   switch (type) {
  //     case 'error':
  //       this.showDanger(msg)
  //       break;

  //     case 'success':
  //       this.showSuccess(msg)
  //       break;

  //     case 'warning':
  //       this.showWarning(msg)
  //       break;

  //     default:
  //       this.showStandard(msg);
  //       break;
  //   }
  // }

  private toasterService: ToasterService;

  public toasterconfig: ToasterConfig =
    new ToasterConfig({
      tapToDismiss: true,
      timeout: 3000
    });

  constructor(toasterService: ToasterService) {
    this.toasterService = toasterService;
    // console.log(this.toasterService);

  }

  private showSuccess() {
    console.log('success');
    setTimeout(() => {
      this.showWarning(); console.log('warning');
    }, 1000);
    this.toasterService.pop('success', 'Success Toaster', 'This is toaster description');
  }

  public showError(msg: string, desc: string = '') {
    this.toasterService.pop('error', `Error ${msg}`, desc);
  }

  private showWarning() {
    this.toasterService.pop('warning', 'Warning Toaster', 'This is toaster description');
  }

  private showInfo() {
    this.toasterService.pop('info', 'Info Toaster', 'This is toaster description');
  }

  private showPrimary() {
    this.toasterService.pop('primary', 'Primary Toaster', 'This is toaster description');
  }

  showErrorToastInArray(errorArray: any) {
    errorArray.forEach(msg => {
      // console.log(msg);
      this.showError('', msg);
    });
  }

  showToastMsg(type: string, title: string, msg: string='') {
    console.log("showToastMsg",type, title, msg);

    this.toasterService.pop(type, title, msg)
  }


}
