import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';


import { ConfigurationService } from '../configuration.service';
import { Router } from '@angular/router';
import { ToastService } from '../toast.service';

import { ToastrService } from 'ngx-toastr';



@Injectable()
export class AuthService {

    private baseURL = "";
    public token = "";
    public currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;
    CoursesList: any[] = [];

    constructor(private toastService: ToastService, private toastr: ToastrService, private http: HttpClient, private config: ConfigurationService, private route: Router) {
        this.baseURL = config.getBaseURI()
        this.currentUserSubject = new BehaviorSubject<any>(null);
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    public set currentUserValue(value) {
        this.currentUserSubject.next(value);
    }

    loginsignup(params: any) {
        const criteria = {
            "criteria": {
                "dType": "W",
                "tenant": null,
                ...params
            }
        };



        return this.http.post<any>(this.baseURL + "user/login", criteria).pipe(
            map((data: any) => {
                // console.log(data);

                if (data && data.Data.token) {
                    if (data.Data.registerfor) {
                        this.token = data.Data.token;
                        this.toastr.success(data["errorDescription"] || 'Login', '');

                        this._syncUser(data);
                        // console.log(this.token);
                    } else {
                        this.toastr.warning("Register for not set. Please contact to admin", '');
                    }

                } else {
                    this.toastr.error(data["errorDescription"] || 'error', '');
                }
                return data;
            }));
    }



    refreshtoken(params = null) {
        return this.http.post<any>(this.baseURL + "refreshtoken", params).pipe(
            map(data => {
                this._syncUser(data);
                return data;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        // localStorage.removeItem('currentUser');
        this.token = '';
        this.route.navigateByUrl("/home");
        this.currentUserSubject.next(null);
        location.reload()
        this.toastr.warning("Logout Successfully!", '');

        // Remove all saved data from sessionStorage
        sessionStorage.clear();
        localStorage.clear();
    }

    _syncUser(data: any) {
        console.log(data);
        
        // localStorage.setItem('currentUser', JSON.stringify(data));
        this.currentUserSubject.next(data);
    }
    getApi(endpoint: string, params: any = null) {
        let httpParams = new HttpParams();
        if (params)
            httpParams = httpParams.append("criteria", JSON.stringify(params));
        return this.http.get(this.baseURL + endpoint, { params: httpParams })
    }


    getProgramCatlouge(thisRef: any, callBackFun: Function) {
        if (!this.CoursesList.length) {
            const endpoint = 'program/getProgramCatlouge'
            this.getApi(endpoint).subscribe((res: any) => {
                if (res.errorCode == "000000") {
                    this.CoursesList = [];
                    this.CoursesList = res.Data
                    this.CoursesList.forEach((item: any) => {
                        item['assignedprogramlist'].forEach((data: any, i: number) => {
                            if (data['imagepath']) {
                                data['imagepath'] = data['imagepath'].replace(/uploads_demo/g, "uploads")
                            }
                            if (data['viewcourseimgpath']) {
                                data['viewcourseimgpath'] = data['viewcourseimgpath'].replace(/uploads_demo/g, "uploads")
                            }
                        })
                    })
                    callBackFun(thisRef, res.Data);
                }
            }, err => { })
        } else
            callBackFun(thisRef, this.CoursesList);
    }

    

}
