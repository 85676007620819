import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { ApiRequestsService } from './services/api-requests.service';
import { AuthService } from './services/auth/auth.service';
import { HelpersService } from './services/helpers.service';
import { Subscription } from 'rxjs';
export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'enhance-and-excel-mmaster-2-angular';
  errorMsg:string = "";
  subscription: Subscription;
  constructor(private helperService:HelpersService, private api: ApiRequestsService, private route: Router, private authService:AuthService) {
    this.subscription = route.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !route.navigated;
      }
  });
  }
  ngOnInit(): void {
    console.log("App Init");

    let data: any = sessionStorage.getItem("zxcvb");
    console.log("sessionStorage data", data);

    if (!data) {
      data = localStorage.getItem('zxcvb');
 //     console.log("localStorage data", data);
    }

    if (data) {
      data = JSON.parse(data);

      this.autoLogin(data);
    }

    this.api.setLoderEle();
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  autoLogin(res: any) {
    console.log("masterclass autoLogin",res);
    if (res.errorCode == "000000" && res.Data.token) {
      this.authService.token = res.Data.token;
      this.authService.currentUserValue = res;
 //     console.log("authService token", this.authService.token);
      
      this.helperService.storeResponseData = res;
     
      if (res.Data.registerfor == "T") {
        window.location.href = location.origin + '/testcenter';
        return;
      } else {
// console.log("Got to my courses");
        
        this.route.navigateByUrl('/dashboard/my-courses');
      }
    } else {
      this.errorMsg = res.errorDescription;
    }
  }
}
