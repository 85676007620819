import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { CurrentUser, InstituteUserRule, Institution_ui_mapping } from '../../interfaces/user';
import { ApiRequestsService } from '../../services/api-requests.service';
import { AuthService } from '../../services/auth/auth.service';
import { DashboardApiService } from '../../services/dashboard-api.service';
import * as AOS from 'aos';
import { saveAs } from 'file-saver';
import { HelpersService } from '../../services/helpers.service';
import { HttpParams } from '@angular/common/http';
import { EncrptDecreptService } from '../../services/encrpt-decrept.service';
import { log } from 'console';

declare var $: any;

@Component({
  selector: 'app-common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.scss']
})
export class CommonHeaderComponent implements OnInit {
  @ViewChild('loginModal', { static: false }) public loginModal: ModalDirective;
  @ViewChild('notesModal', { static: false }) public notesModal: ModalDirective;
  @ViewChild('confimModal', { static: false }) public confimModal: ModalDirective;
  @ViewChild('registrationModal', { static: false }) public registrationModal: ModalDirective;
  @ViewChild('alertModal', { static: false }) public alertModal: ModalDirective;

  modalRef?: BsModalRef;
  public model: any = {
    uid: "",
    pw: ""
  }

  public registermodel: any = {
    nm: "",
    eml: "",
    mob: "",
    ct: "",
    pw: ""
  }

  public changePwdModel = {
    oldpass: '',
    newpass: '',
    confirmNewPassword: ''
  };
  typeForPassword: string = 'password';
  typeForConPassword: string = 'password';
  passMatchErr: string = "";
  fileName: string = "";
  public canChangePwd: boolean = false;

  public isForgotPassword: boolean = false;

  public mobileDropdownActiveIndex: number = 0;

  private stroreResponseData: any;

  headerPermissionsObj: Institution_ui_mapping;
  cartDataCount = 0;
  currentUser: CurrentUser;
  instutionUserRule: InstituteUserRule;

  masterclassParentMenu: boolean = true;
  testcenterParentMenu: boolean = true;
  reportParentMenu: boolean = true;

  showTestCenterMasterMenu: boolean = false;
  showTestCenterWorksheetMenu: boolean = false;
  userProgramAssignMenu: boolean = false;
  userSessionUpdateMenu: boolean = false;
  adminMasterclassReportMenu: boolean = false;
  admintestcenterclassReportMenu: boolean = false;

  constructor(public helperService: HelpersService, private api: ApiRequestsService,
    private authService: AuthService, public route: Router, private dashboardApiService: DashboardApiService,
    private encryptService: EncrptDecreptService) {
    this.currentUser = this.authService.currentUserValue?.Data;
    this.headerPermissionsObj = this.currentUser?.institution_ui_mapping;
    this.instutionUserRule = this.currentUser?.instituteuserrule;

    if (this.currentUser?.ut == 'SA') {
      this.userProgramAssignMenu = true;
      this.userSessionUpdateMenu = true;

      this.showTestCenterMasterMenu = true;
      this.showTestCenterWorksheetMenu = true;


      this.adminMasterclassReportMenu = true;
      this.admintestcenterclassReportMenu = true;
    }



    if (this.currentUser?.ut == 'IA' && this.instutionUserRule?.instituteallowtoprogramassign == 'Y') {
      this.userProgramAssignMenu = true;
    } else if (this.currentUser?.ut == 'CA' && this.instutionUserRule?.centerallowtoprogramassign == 'Y') {
      this.userProgramAssignMenu = true;
    }

    if (this.currentUser?.ut == 'IA' && this.instutionUserRule?.instituteallowtoupdateusersessioncomplitionstatus == 'Y') {
      this.userSessionUpdateMenu = true;
    } else if (this.currentUser?.ut == 'CA' && this.instutionUserRule?.centerallowtoupdateusersessioncomplitionstatus == 'Y') {
      this.userSessionUpdateMenu = true;
    }

    if (this.currentUser?.ut == 'IA' && this.instutionUserRule?.instituteallowtestcentermasterpart == 'Y') {
      this.showTestCenterMasterMenu = true;
    } else if (this.currentUser?.ut == 'CA' && this.instutionUserRule?.centerallowtestcentermasterpart == 'Y') {
      this.showTestCenterMasterMenu = true;
    }

    if (this.currentUser?.ut == 'IA' && this.instutionUserRule?.instituteallowtestcenterworksheetpart == 'Y') {
      this.showTestCenterWorksheetMenu = true;
    } else if (this.currentUser?.ut == 'CA' && this.instutionUserRule?.centerallowtestcenterworksheetpart == 'Y') {
      this.showTestCenterWorksheetMenu = true;
    }

    if (this.currentUser?.ut == 'IA' && this.instutionUserRule?.instituteallowmasterclassreports == 'Y') {
      this.adminMasterclassReportMenu = true;
    } else if (this.currentUser?.ut == 'CA' && this.instutionUserRule?.centerallowmasterclassreports == 'Y') {
      this.adminMasterclassReportMenu = true;
    }

    if (this.currentUser?.ut == 'IA' && this.instutionUserRule?.instituteallowtestcenterreports == 'Y') {
      this.admintestcenterclassReportMenu = true;
    } else if (this.currentUser?.ut == 'CA' && this.instutionUserRule?.centerallowtestcenterreports == 'Y') {
      this.admintestcenterclassReportMenu = true;
    }

    // if (this.currentUser?.ut == 'IA' || this.currentUser?.ut == 'CA') {
    //   if (this.userProgramAssignMenu && this.userSessionUpdateMenu) [
    //     this.masterclassParentMenu = false
    //   ]

    //   if (this.showTestCenterMasterMenu && this.showTestCenterWorksheetMenu) {
    //     this.testcenterParentMenu = false;
    //   }

    //   if (this.adminMasterclassReportMenu && this.admintestcenterclassReportMenu) {
    //     this.reportParentMenu = false;
    //   }
    // }

    // console.log(this.headerPermissionsObj);

  }

  ngOnInit(): void {
    AOS.init();
    this.authService.currentUserSubject.subscribe((res) => {
      if (this.authService.token) {
        this.showLoginBtn = false;
      } else
        this.showLoginBtn = true;
      this.headerPermissionsObj = this.currentUser?.institution_ui_mapping;
      console.log('headerPermissionsObj',this.headerPermissionsObj);
      

    }, err => { });
    this.canChangePwd = false;
    this.isForgotPassword = false;

    let data: any = localStorage.getItem("cartitems")

    if (data) {
      // this.cartDataCount = JSON.parse(data).length;
    }
    this.cartDataCount = this.helperService.addToCartArrayList.length;

    this.helperService.cartData.subscribe((value: any) => {
      // this.cartDataCount = value.length;
      this.helperService.cartDataCount = this.cartDataCount;
    })


    // let mobile_nav_toggle = document.getElementsByClassName('nav-link');
    // let navbar = document.getElementById('navbar');
    // if (mobile_nav_toggle) {
    //   console.log("click mobile_nav_toggle");
    //   const navTogle = () => {
    //     navbar.classList.toggle('navbar-mobile')
    //   }
    //   window.addEventListener('click', navTogle)
    // }

    // console.log("Common header Init");

    // let data: any = sessionStorage.getItem("qwerty");
    // console.log("sessionStorage data", data);

    // if (!data) {
    //   data = localStorage.getItem('qwerty');
    //   console.log("localStorage data", data);
    // }

    // if (data) {
    //   data = JSON.parse(data);

    //   this.autoLogin(data);
    // }




  }

  // autoLogin(res: any) {
  //   console.log("masterclass autoLogin",res);
  //   if (res.errorCode == "000000" && res.Data.token) {
  //     this.authService.token = res.Data.token;
  //     console.log("authService token", this.authService.token);

  //     this.helperService.storeResponseData = res;

  //     if (res.Data.registerfor == "T") {
  //       window.location.href = location.origin + '/testcenter';
  //       return;
  //     } else {
  //       console.log("Got to my courses");

  //       this.route.navigateByUrl('/dashboard/my-courses');
  //     }
  //   } else {
  //     this.errorMsg = res.errorDescription;
  //   }
  // }

  clickToOpenMenu() {
    let navbar = document.getElementById('navbar');
    let navbarIcon = document.getElementById('click-on-bars');
    if (navbar) {
      navbar.classList.toggle('navbar-mobile');
      navbarIcon.classList.toggle('bi-list');
      navbarIcon.classList.toggle('bi-x');
    }
    this.clickToOpenDropdown(0);
  }

  clickToOpenDropdown(idIndex: number) {
    let dropdown = document.getElementById(`dropdownactive${idIndex}`)
    dropdown?.classList.toggle('dropdown-active');
    this.mobileDropdownActiveIndex = idIndex;
  }
  clickToCloseMobileMenu() {
    let navbar = document.getElementById('navbar');
    let navbarIcon = document.getElementById('click-on-bars');
    navbar.classList.remove('navbar-mobile');
    navbarIcon.classList.add('bi-list');
    navbarIcon.classList.remove('bi-x');
  }
  logOut() {
    // sessionStorage.setItem("institute", storgeData);
    let data = sessionStorage.getItem("institute");
    console.log('data',data);
    
    let val = JSON.parse(data)
    if (val?.intuitId != null) {
      this.route.navigate(['institute-info'],{queryParams:{loginid:val.uid,intuitId:val.intuitId}});
      sessionStorage.clear();
    }else{
      this.route.navigateByUrl('/home');
      this.authService.logout();
    }
   
  }

  showDashboard = false;
  showCartItem = true;
  showUserName = true;
  showLoginBtn = false;
  disabledLoginBtn = false;
  config = {
    animated: true
  };
  errorMsg: string = "";
  successMsg: string = "";


  openModal() {
    this.errorMsg = '';
    this.disabledLoginBtn = false;
    this.isForgotPassword = false;
    if (this.authService.token && !this.canChangePwd) this.route.navigateByUrl('/dashboard/my-courses');
    else
      this.loginModal.show()
    this.successMsg = "";
    this.errorMsg = "";
    this.model.uid = "";
    this.model.pw = "";
    this.changePwdModel.newpass = "";
    this.changePwdModel.oldpass = "";
    this.changePwdModel.confirmNewPassword = "";
  }

  closeModal() {
    this.loginModal.hide();
    this.disabledLoginBtn = true;
    this.canChangePwd = false;
    this.isForgotPassword = false;
  }

  openRegister() {
    this.registrationModal.show()
  }
  closeRegister() {
    this.registrationModal.hide()
  }

  registerUser() {
    if (!this.registermodel.nm || !this.registermodel.eml) {
      this.errorMsg = 'Enter note'; return;
    }

    const endpoint: string = 'user/registerUser';
    this.registermodel = JSON.stringify(this.registermodel);
    let data = this.encryptService.encrypt(this.registermodel);

    this.api.postApiCall(endpoint, { ox: data }).subscribe((res: any) => {
      if (res['errorCode'] == this.api.NO_ERROR) {
        this.errorMsg = res['errorDescription']
      } else {
        this.errorMsg = res['errorDescription']
      }
    }, (err: any) => {
      console.log(err);

    }, () => { })


  }

  LoginAction() {
    if (!this.model.pw || !this.model.uid) {
      return
    }
    this.disabledLoginBtn = true;
    this.model.uid = this.model.uid.trim()
    console.log(this.model);
    
    this.model = JSON.stringify(this.model)
    let data = this.encryptService.encrypt(this.model)
    this.authService.loginsignup({ ox: data }).subscribe((res) => {
      if (res.errorCode == "000000" && res.Data.token) {
        this.helperService.storeResponseData = res;
        this.helperService.userType = res?.Data?.ut;

        this.helperService.userId = res?.Data?.uid;
        this.helperService.userName = res?.Data?.uname;
        console.log(this.helperService.userName);
        
        this.helperService.type = res?.Data?.type;
        const storgeData = JSON.stringify(res);
        sessionStorage.setItem("qwerty", storgeData);
        // localStorage.setItem('qwerty', storgeData);
        if (res.Data.registerfor == "T") {
          window.location.href = location.origin + '/testcenter';
          return;
        } else if (this.helperService.LoginFrom == "cart") {
          this.route.navigateByUrl('/add-to-cart');
        } else if (res?.Data?.ut == 'SA' || res?.Data?.ut == 'IA' || res?.Data?.ut == 'CA') {
          this.route.navigateByUrl('/dashboard/user-list');
        } else {
          this.route.navigateByUrl('/dashboard/my-courses');

        }
        this.closeModal();
      } else {
        this.errorMsg = res.errorDescription;
        this.model = JSON.parse(this.model)
      }

    }, (err) => {
      console.error(err);
      this.disabledLoginBtn = false;
      // this.route.navigateByUrl('/dashboard/my-courses');
    }
      , () => {
        // console.log('ksndfsd');
        this.disabledLoginBtn = false;

        // this.showdashboard = true;
        // this.showCartItem = true;
        // this.showUserName = true;
        // this.showLoginBtn = false;
      });
  }

  changePassword() {
    this.errorMsg = '';
    if (!this.changePwdModel.newpass || !this.changePwdModel.confirmNewPassword || !this.changePwdModel.oldpass) {
      this.errorMsg = 'Enter all fields'
      return
    }
    if (this.changePwdModel.newpass != this.changePwdModel.confirmNewPassword) {
      this.errorMsg = 'new password and confirm new password does not match';
      return
    }
    const endpoint: string = 'user/changePassword';
    let params: any = { oldpass: this.changePwdModel.oldpass, newpass: this.changePwdModel.newpass };
    params = JSON.stringify(params);
    let data = this.encryptService.encrypt(params)
    this.api.postApiCall(endpoint, { ox: data }).subscribe((res: any) => {
      if (res['errorCode'] == this.api.NO_ERROR) {
        // console.log(res);
        this.closeModal()
        this.confimModal.show();

      } else {
        this.errorMsg = res.errorDescription;
      }

    }, (err) => {
      this.disabledLoginBtn = false;
    }, () => { })
  }

  forgotPassword() {
    this.errorMsg = '';
    if (!this.model.uid) {
      this.errorMsg = 'Enter User ID'
      return
    }
    const endpoint: string = 'user/forgotPassword';
    let params: any = { loginid: this.model.uid };
    
    params = JSON.stringify(params);
    let data = this.encryptService.encrypt(params);
    this.api.postApiCall(endpoint, { ox: data }).subscribe((res: any) => {
      if (res['errorCode'] == this.api.NO_ERROR) {
        this.isForgotPassword = false;
        this.successMsg = res.errorDescription;
        // this.closeModal();
      } else {
        this.errorMsg = res.errorDescription;
      }

    }, (err) => {
      this.disabledLoginBtn = false;
    }, () => { })
  }

  changeViewOfPasswrd(FOR: string, typeForPassword: string) {
    // console.log("click");

    if (typeForPassword == 'password') {
      if (FOR == 'pass') {
        this.typeForPassword = 'text';
      }
      else {
        this.typeForConPassword = 'text';
      }
    }
    else {
      if (FOR == 'pass') {
        this.typeForPassword = 'password';
      }
      else {
        this.typeForConPassword = 'password';
      }
    }
  }

  checkPassword(eve: string) {
    // console.log(this.changePwdModel.newpass);
    if (this.changePwdModel.confirmNewPassword) {
      this.passMatchErr = "";
    }
    if (this.changePwdModel.newpass == eve) {
      this.disabledLoginBtn = false;
      this.passMatchErr = "";
    } else {
      this.disabledLoginBtn = true;
      this.passMatchErr = "New password and confirmed password not matched.";
    }
  }

  Ok() {
    this.logOut();
    this.confimModal.hide();
  }
  cancel() {
    this.alertModal.hide();
  }

  redirectToMyTest() {
    let res = this.helperService.storeResponseData;
    res['Data']["userInfo"]['taketest'] = 'N';
    const storgeData = JSON.stringify(res);
    sessionStorage.setItem("qwerty", storgeData);

    if (res) {
      window.location.href = location.origin + '/testcenter';
      return;
    }
  }

  downloadFileName() {
    // console.log("downloadFileName");

    let endpoint = "generatetest/getdump";
    this.api.getApiCall(endpoint).subscribe(successResp => {

      if (successResp['errorCode'] == "000000") {

        this.fileName = successResp['Data']['filename'];
        // console.log(this.fileName);

        if (this.fileName) {
          this.alertModal.show();
        }
      }
    }, (err) => {
      console.log(err);
    });
  }

  downloadBackupFile() {
    let params: any = { "filename": this.fileName };
    params = JSON.stringify(params);
    let data = this.encryptService.encrypt(params);
    let endpoint = 'program/downloadFile'
    this.api.downloadFilesReq(endpoint, { ox: data }).subscribe(successResp => {

      var blob = new Blob([successResp], { type: 'application/sql' });
      var url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = this.fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
      this.fileName = "";
      this.cancel();
    },
      err => {
        alert("Problem while downloading the file.");
        console.error(err);
      }
    );

  }



  menuNavigateToTestcenter(tab: string) {
    let res = this.helperService.storeResponseData;
    let type = '';
    switch (tab) {
      case 'standardmaster':
        type = 'STDM';
        break;
      case 'subjectmaster':
        type = 'SUBM';
        break;
      case 'chaptermaster':
        type = 'CHPM';
        break;
      case 'topicmaster':
        type = 'TOPM';
        break;
      case 'questioncategory':
        type = 'QUEC';
        break;
      case 'questionmaster':
        type = 'QUEM';
        break;
      case 'userguidline':
        type = 'UGL';
        break;
      case 'testmaster':
        type = 'TM';
        break;

      case 'usertestassign':
        type = 'UTA';
        break;
      case 'updateusertest':
        type = 'UUT';
        break;
      case 'facultytestremark':
        type = 'FTR';
        break;
      case 'studentpaperreview':
        type = 'SPR';
        break;
      case 'studentwisetestcount':
        type = 'STC';
        break;
      case 'resettest':
        type = 'RT';
        break;
      case 'usertestreport':
        type = 'UTR';
        break;
      default:
        break;
    }
    res['Data']["userInfo"]['navigateto'] = `${type}`;

    const storgeData = JSON.stringify(res);
    sessionStorage.setItem("qwerty", storgeData);

    if (res) {
      window.location.href = location.origin + '/testcenter';
      return;
    }
  }

  updateUserTest() {
    let res = this.helperService.storeResponseData;
    res['Data']["userInfo"]['updateusertest'] = 'UP';
    const storgeData = JSON.stringify(res);
    sessionStorage.setItem("qwerty", storgeData);

    if (res) {
      window.location.href = location.origin + '/testcenter';
      return;
    }
  }
}
