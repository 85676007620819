import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiRequestsService } from '../../services/api-requests.service';
import { EncrptDecreptService } from '../../services/encrpt-decrept.service';

@Component({
  selector: 'app-feedback-popup',
  templateUrl: './feedback-popup.component.html',
  styleUrls: ['./feedback-popup.component.scss']
})
export class FeedbackPopupComponent implements OnInit {

  model: any = {
    name: "",
    eid: "",
    sub: "",
    msg: ""
  }
  public isLoading = false;
  errorMsg: string = "";


  constructor(private encryptService: EncrptDecreptService, private ngbActiveModal: NgbActiveModal, private api: ApiRequestsService) { }

  ngOnInit(): void {
  }

  feedbackSubmitForm() {
    console.log(this.model);

    if (!this.model.name || !this.model.eid || !this.model.sub || !this.model.msg) {
      this.errorMsg = 'Enter note'; return;
    }

    const endpoint: string = 'program/insertUserFeedback';
    this.model = JSON.stringify(this.model);
    let data = this.encryptService.encrypt(this.model);
    this.isLoading = true;
    this.api.postApiCall(endpoint, { ox: data }).subscribe((res: any) => {
      if (res['errorCode'] == this.api.NO_ERROR) {
        // console.log(res);
        this.closeModal()
      }
      this.errorMsg = res['errorDescription']
      this.isLoading = false;
    }, (err: any) => {
      this.isLoading = false;
    }, () => { })

  }

  closeModal(isclose = true) {
    this.ngbActiveModal.close();
  }

}
