// export const iFRAME_LINK: string = 'http://127.0.0.1:5500/courses/Operations_Function_Scorm_2004_V1_captivate/index_scorm.html';
// export const iFRAME_LINK: string = 'assets/courses/Operations_Function_Scorm_2004_V1_captivate/index_scorm.html';
// export const iFRAME_LINK: string = 'assets/courses/Operations_Function_Scorm_1.2_V1/index_scorm.html?userid=101';
// export const iFRAME_LINK: string = 'assets/courses/M2_Managing_Energy_V3_Storyline_SCORM_2004_CompleteIncomplete/index_lms.html';
// export const iFRAME_LINK: string = 'assets/courses/Operations_Functions_V3_Captivate_SCORM_1.2_PassedIncomplete/index_scorm.html';
// export const iFRAME_LINK: string = 'assets/courses/Operations_Functions_V3_Captivate_SCORM_2004_CompleteIncomplete/index_scorm.html';

// export const iFRAME_LINK: string = 'assets/courses/M2_Managing_Energy_V3_Storyline_SCORM_1.2_PassedIncomplete/index_lms.html?userid=101';

//May-23-2022
// export const iFRAME_LINK: string = 'assets/courses/may-23-2022/M1_SCORM_1.2_QUIZ/index_lms.html';
// export const iFRAME_LINK: string = 'assets/courses/may-23-2022/M1_SCORM_2004_QUIZ/index_lms.html';
// export const iFRAME_LINK: string = 'assets/courses/may-23-2022/M1_SCORM_1.2_SLIDE/index_lms.html';
export const iFRAME_LINK: string = 'assets/courses/may-23-2022/M1_SCORM_2004_SLIDE/index_lms.html';

// export const iFRAME_LINK: string = 'http://localhost:3000/courses';



// export const CMI_OBJ: any = {"cmi":{"suspend_data":"I1A%24nP1A%24nP0A%24nP1A%24nP1A%24nP1A%24nP1A%24nP1A%24nP1A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP000AA0000C-1A0NGSingleBADchgBATcpQuizInfoStudentIDBAVcpQuizInfoStudentNameACROEhighBAElifeBADobjBAGreplayB.BoEIreplay13BAEriskBAIteamworkBAEuniqBAQcpQuizHandledAllBA$_#-#_$","launch_data":"","comments":"","comments_from_lms":"","core":{"student_id":"1234","student_name":"RO","lesson_location":"Order%20Penetration%20Point","credit":"","lesson_status":"incomplete","entry":"","lesson_mode":"normal","exit":"suspend","session_time":"0000:00:00.42","score":{"raw":"0","min":"0","max":"100"}},"objectives":{},"student_data":{"mastery_score":"","max_time_allowed":"","time_limit_action":""},"student_preference":{"audio":"","language":"","speed":"","text":""},"interactions":{}}}
export const CMI_OBJ: any ={};
//M1_SCORM_1.2_QUIZ
// {"cmi":{"suspend_data":"2Aae60708090a0b0c0IH1001313z01012011120121201013011130121301313~2u1b000000000001^1^1^1^1^1^1^1^1^1^10101010101010101010101010v_player.5gyay7iAdcF.6EBMWU5yrTT1^1^000~2T7~2W3h41110b101021010113gs2~2q33400340034003400342034203420342034203420g620101^8_default3420x7b000o000o0141^h_default_Disabledx7b000o0Q3r0141^h_default_DisabledG7b030o0H2q01c1^q_default_Selected_Disabledx7b000o0z1p0141^h_default_Disabled342034003020002140~2Q3f210b101021010113A41~2m33400340034003400342034203420342034203420g620101^8_default3420x7b000o0C2q0141^h_default_Disabledx7b000o000o0141^h_default_DisabledG7b030o0W3r01c1^q_default_Selected_Disabledx7b000o0j1p0141^h_default_Disabled34203400002140000002000","launch_data":"","comments":"","comments_from_lms":"","core":{"student_id":"1234","student_name":"RO","lesson_location":"","credit":"","lesson_status":"incomplete","entry":"","lesson_mode":"normal","exit":"suspend","session_time":"0000:06:02.39","score":{"raw":"","min":"","max":"100"},"total_time":"00:06:02.39"},"objectives":{},"student_data":{"mastery_score":"","max_time_allowed":"","time_limit_action":""},"student_preference":{"audio":"","language":"","speed":"","text":""},"interactions":{}}}
//M1_SCORM_2004_QUIZ
// {"cmi":{"comments_from_learner":{},"comments_from_lms":{},"completion_status":"incomplete","completion_threshold":"","credit":"credit","entry":"","exit":"suspend","interactions":{},"launch_data":"","learner_id":"1234","learner_name":"RO","learner_preference":{"audio_level":"1","language":"","delivery_speed":"1","audio_captioning":"0"},"location":"","max_time_allowed":"","mode":"normal","objectives":{},"progress_measure":"","scaled_passing_score":"","score":{"scaled":"","raw":"","min":"","max":""},"session_time":"PT1M","success_status":"unknown","suspend_data":"2l2860708090ts1001013k01012011120121201013~2u1b000000000001^1^1^1^1^1^1^1^1^1^10101010101010101010101010v_player.5gyay7iAdcF.6VgFK615QWV1^1^000000002000","time_limit_action":"continue,no message","total_time":"PT2M1.16S"}}
//M1_SCORM_1.2_SLIDE
//{"cmi":{"suspend_data":"2Ueo60708090a0b0c0d0e0f0g0h0~281~2411001813Y010120111201212010130111301213013130141301513016130171301813~2u1b000000000001^1^1^1^1^1^1^1^1^1^10101010101010101010101010v_player.5gyay7iAdcF.6FP3mDosMqL1^1^000~2yb~2W3h41110b101021010113gs2~2q33400340034003400342034203420342034203420g620101^8_default3420x7b000o000r0141^h_default_Disabledx7b000o0H2q0141^h_default_DisabledG7b030o0Q3p01c1^q_default_Selected_Disabledx7b000o0z1o0141^h_default_Disabled342034003020002140~2E3f210b101001a1a103T_0~2a33400340034003400342034203420342034203420g620101^8_default3420r7a000141^h_default_Disabledr7a000141^h_default_Disabledx7b000o000o0141^h_default_DisabledG7b050o0j1p01c1^q_default_Selected_Disabled34203400002140~2Q3f210b101001a1a103Da1~2m33400340034003400342034203420342034203420g620101^8_default3420x7b000o000o0141^h_default_Disabledx7b000o0j1p0141^h_default_Disabledx7b000o0W3r0141^h_default_DisabledG7b050o0C2q01c1^q_default_Selected_Disabled3420340000214000002000","launch_data":"","comments":"","comments_from_lms":"","core":{"student_id":"1234","student_name":"RO","lesson_location":"","credit":"","lesson_status":"incomplete","entry":"","lesson_mode":"normal","exit":"suspend","session_time":"0000:03:01.81","score":{"raw":"","min":"","max":"100"},"total_time":"00:03:01.81"},"objectives":{},"student_data":{"mastery_score":"","max_time_allowed":"","time_limit_action":""},"student_preference":{"audio":"","language":"","speed":"","text":""},"interactions":{}}}
//M1_SCORM_2004_SLIDE
// {"cmi":{"comments_from_learner":{},"comments_from_lms":{},"completion_status":"incomplete","completion_threshold":"","credit":"credit","entry":"","exit":"suspend","interactions":{},"launch_data":"","learner_id":"1234","learner_name":"RO","learner_preference":{"audio_level":"1","language":"","delivery_speed":"1","audio_captioning":"0"},"location":"","max_time_allowed":"","mode":"normal","objectives":{},"progress_measure":"","scaled_passing_score":"","score":{"scaled":"","raw":"","min":"","max":""},"session_time":"PT59.98S","success_status":"unknown","suspend_data":"2HfA60708090a0b0c0d0e0f0g0h0i0j0k0l0m0n0~2F1~2B11001e13~2q10101201112012120101301113012130131301413015130161301713018130191301a1301b1301c1301d1301e13~2u1b000000000001^1^1^1^1^1^1^1^1^1^10101010101010101010101010v_player.5gyay7iAdcF.6ZeBkJ59opM1^1^000~2Eb~2W3h41110b101021010123gs2~2q33400340034003400342034203420342034203420g620101^8_default3420x7b000o091r0141^h_default_DisabledG7b030o0H2q01c1^q_default_Selected_Disabledx7b000o000p0141^h_default_Disabledx7b000o0Q3o0141^h_default_Disabled342034003020002140~2Q3f210b101021010123oV0~2m33400340034003400342034203420342034203420g620101^8_default3420x7b000o000o0141^h_default_DisabledG7b030o0j1p01c1^q_default_Selected_Disabledx7b000o0C2q0141^h_default_Disabledx7b000o0W3r0141^h_default_Disabled34203400002140~2K3f210b101001a1a103pR0~2g33400340034003400342034203420342034203420g620101^8_default3420r7a000141^h_default_Disabledx7b000o0j1p0141^h_default_Disabledx7b000o000o0141^h_default_DisabledG7b050o0C2q01c1^q_default_Selected_Disabled3420340000214000002000","time_limit_action":"continue,no message","total_time":"PT59.98S"}}
//{"cmi":{"comments_from_learner":{},"comments_from_lms":{},"completion_status":"incomplete","completion_threshold":"","credit":"credit","entry":"","exit":"suspend","interactions":{},"launch_data":"","learner_id":"1234","learner_name":"RO","learner_preference":{"audio_level":"1","language":"","delivery_speed":"1","audio_captioning":"0"},"location":"","max_time_allowed":"","mode":"normal","objectives":{},"progress_measure":"","scaled_passing_score":"","score":{"scaled":"","raw":"","min":"","max":""},"session_time":"PT3M0.84S","success_status":"unknown","suspend_data":"2mae60708090a0b0c0IH1001313z01012011120121201013011130121301313~2u1b000000000001^1^1^1^1^1^1^1^1^1^10101010101010101010101010v_player.5gyay7iAdcF.6EBMWU5yrTT1^1^000~2F7~2O3f210b101021010133Lr2~2k33400340034003400342034203420342034203420g620101^8_default3420G7b030o0h2q01c1^q_default_Selected_Disabledx7b000o000o0141^h_default_Disabledr7a000141^h_default_Disabledx7b000o0Q3r0141^h_default_Disabled342034003020002140~2K3f210b101021010123y21~2g33400340034003400342034203420342034203420g620101^8_default3420x7b000o0C2q0141^h_default_DisabledG7b030o000o01c1^q_default_Selected_Disabledr7a000141^h_default_Disabledx7b000o0W3r0141^h_default_Disabled34203400002140000002000","time_limit_action":"continue,no message","total_time":"PT3M0.84S"}}
//{"cmi":{"suspend_data":"N1A%24nP1A%24nP0A%24nP1A%24nP0A%24nP1A%24nP1A%24nP1A%24nP1A%24nP1A%24nP1A%24nP1A%24nP1A%24nP1A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP000AA0000C-1B0NGSingleBADchgBATcpQuizInfoStudentIDBAVcpQuizInfoStudentNameACROEhighBAElifeBADobjBAGreplayB.hqIIreplay13BAEriskBAIteamworkBAEuniqBAQcpQuizHandledAllBA$_#-#_$","launch_data":"","comments":"","comments_from_lms":"","core":{"student_id":"1234","student_name":"RO","lesson_location":"Quiz%20-%20True%20or%20False%20-%20Question%201","credit":"","lesson_status":"incomplete","entry":"","lesson_mode":"normal","exit":"suspend","session_time":"0000:01:12.3","score":{"raw":"0","min":"0","max":"100"}},"objectives":{},"student_data":{"mastery_score":"","max_time_allowed":"","time_limit_action":""},"student_preference":{"audio":"","language":"","speed":"","text":""},"interactions":{}}}



export const cmi2004: any = { "cmi": { "comments_from_learner": {}, "comments_from_lms": {}, "completion_status": "incomplete", "completion_threshold": "", "credit": "credit", "entry": "", "exit": "suspend", "interactions": {}, "launch_data": "", "learner_id": "", "learner_name": "", "learner_preference": { "audio_level": "1", "language": "", "delivery_speed": "1", "audio_captioning": "0" }, "location": "General%20Instructions", "max_time_allowed": "", "mode": "normal", "objectives": {}, "progress_measure": "", "scaled_passing_score": "", "score": { "scaled": "0", "raw": "0", "min": "0", "max": "60" }, "session_time": "PT0.67S", "success_status": "unknown", "suspend_data": "C1A%24nP1A%24nP1A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP000AA0000C-1A0NGSingleBADchgBATcpQuizInfoStudentIDBAVcpQuizInfoStudentNameBAEhighBAElifeBADobjBAGreplayBAIreplay13BAEriskBAIteamworkBAEuniqBAQcpQuizHandledAllBA$_#-#_$", "time_limit_action": "continue,no message", "total_time": "PT0.67S" } }
export const cmi1_2 :any = {"cmi":{"suspend_data":"C1A%24nP1A%24nP1A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP0A%24nP000AA0000C-1A0NGSingleBADchgBATcpQuizInfoStudentIDBAVcpQuizInfoStudentNameBAEhighBAElifeBADobjBAGreplayBAIreplay13BAEriskBAIteamworkBAEuniqBAQcpQuizHandledAllBA$_#-#_$","launch_data":"","comments":"","comments_from_lms":"","core":{"student_id":"","student_name":"","lesson_location":"General%20Instructions","credit":"","lesson_status":"incomplete","entry":"","lesson_mode":"normal","exit":"suspend","session_time":"0000:00:02.18","score":{"raw":"0","min":"0","max":"100"},"total_time":"00:00:02.18"},"objectives":{},"student_data":{"mastery_score":"","max_time_allowed":"","time_limit_action":""},"student_preference":{"audio":"","language":"","speed":"","text":""},"interactions":{}}}
