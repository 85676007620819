import { Injectable } from '@angular/core';


import pdfMake from '../../../../node_modules/pdfmake/build/pdfmake';
import pdfFonts from '../../../../node_modules/pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
// declare let pdfMake: any;


@Injectable({
  providedIn: 'root'
})
export class PdfService {

  constructor() { }

  private formatDate(dt, time = false) {
    if (dt == "" || dt == null)
      return "";
    let d = new Date(dt);
    let dd = String(d.getDate());
    let mm = String(d.getMonth() + 1); //January is 0!
    let yyyy = d.getFullYear().toString();
    if (time) {
      let convention = "";
      let min: any = d.getMinutes() ;
      let hr = d.getHours() ;
      if (min < 10) { min = "0" + min }
      if (hr > 0 && hr < 12) {
        convention = " AM";
      } else {
        convention = " PM";
        hr = hr - 12
      }
      return dd + '/' + mm + '/' + yyyy + ' ' + hr + ':' + min + convention;
    }
    else
      return dd + '/' + mm + '/' + yyyy;

  }
  generatePdf(pdfData, outputType = 'download') {
    console.log('generatePdf', pdfData);/*
    pdfMake.tableLayouts = {
      exampleLayout: {
        hLineWidth: function (i, node) {
          if (i === 0 || i === node.table.body.length) {
            return 0;
          }
          return (i === node.table.headerRows) ? 2 : 1;
        },
        vLineWidth: function (i) {
          return 0;
        },
        hLineColor: function (i) {
          return i === 1 ? 'black' : '#aaa';
        },
        paddingLeft: function (i) {
          return i === 0 ? 0 : 8;
        },
        paddingRight: function (i, node) {
          return (i === node.table.widths.length - 1) ? 0 : 8;
        }
      }
    };*/

    const documentDefinition = this.makePdfDocumentDefinition(pdfData);//{ content: 'This is an sample PDF printed with pdfMake' };
    if (outputType == 'download') {
      pdfMake.createPdf(documentDefinition).download();//Download the PDF
    }
    if (outputType == 'openwindow') {
      const win = window.open('', '_blank');

      pdfMake.createPdf(documentDefinition).open({}, win);//Open PDF in same window
    }
    if (outputType == 'open') {
      pdfMake.createPdf(documentDefinition).open();//Open the PDF in new window
    }
    if (outputType == 'print') {
      pdfMake.createPdf(documentDefinition).print();//Print the PDF
    }

  }

  private drawFooter(pdfFooterdata, printsettings, pageData, branchdata: any = [{}]) {
    if (printsettings['printfooter'] == 'Y') {
      if (printsettings['printfootermsg'] == "Y") {
        return [
          {
            margin: [5, 20],
            columns: [
              [
                { text: "." + branchdata.mailid, style: "footerCenterWithSize8", width: 'auto' },
                { text: "-------------------------------------------------", style: "footerCenterWithSize8" },
                { text: branchdata.gstn, style: "footerCenterWithSize8", width: 'auto' },
              ],
              [
                { text: pdfFooterdata?.footerline3, style: "footerCenterWithSize8", width: 'auto' },
                { text: "-------------------------------------------------", style: "footerCenterWithSize8" },
                { text: pdfFooterdata?.footerline1, style: "footerCenterWithSize6", width: 'auto' },
                { text: pdfFooterdata?.footerline2, style: "footerCenterWithSize8", width: 'auto' },
                { text: pdfFooterdata?.footermsg1, style: "footerCenterWithSize8", width: 'auto' },
                { text: pdfFooterdata?.footermsg2, style: "footerCenterWithSize8", width: 'auto' },

              ],
              [
                { text: branchdata.webaddress, style: "footerCenterWithSize8", width: 'auto' },
                { text: "-------------------------------------------------", style: "footerCenterWithSize8" },
                { text: branchdata.pan, style: "footerCenterWithSize8", width: 'auto' },
              ],
            ],
          },
          { text: 'page ' + pageData.currentPage.toString() + ' of ' + pageData.pageCount, alignment: 'right' }
        ]
      }
      else {
        return null;
      }
    } else {
      return [
        { canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595, y2: 5, lineWidth: 2 }] },

        { text: 'page ' + pageData.currentPage.toString() + ' of ' + pageData.pageCount, alignment: 'right', margin: [5, 2, 10, 20] }];
    }
  }

  private drawHeader(printsettings, headerdata, pageData, trnxmaster: any = {}) {
    if (printsettings['printheader'] == 'Y') {
      if (printsettings['printheadermsg'] == 'Y') {
        return [
          {
            columns: [
              [
                { text: headerdata[0]['headerline1'], fontSize: 7, alignment: "center", bold: true },
                { text: headerdata[0]['headerline2'], fontSize: 37, alignment: "center", bold: true },
                { text: headerdata[0]['headerline3'], fontSize: 11, alignment: "center", bold: true },
                // { text: headerdata[0]['headermsg1'], fontSize: 10, alignment: "center", bold: true },
                // { text: headerdata[0]['headermsg2'], fontSize: 10, alignment: "center" },
                {
                  table: {
                    widths: ['*'],
                    body: [[" "], [" "]]
                  },
                  layout: {
                    hLineWidth: function (i, node) {
                      return (i === 0 || i === node.table.body.length) ? 0 : 2;
                    },
                    vLineWidth: function (i, node) {
                      return 0;
                    },
                  }
                }
              ],
            ],
            margin: [0, 5, 0, 0]
          }
        ]
      } else {
        return null;
      }
    }
  }

  private drawTable(pdfTableHeader, pdfTableData, printsettings) {
    return {
      alignment: printsettings['tablealignment'],
      fontSize: printsettings['tableFontSize'],
      layout: {
        hLineWidth: (i, node) => {
          return (i == 0 || i == 1 || i === node.table.body.length) ? 2 : 1;
        },
        // vLineWidth: function (i, node) {
        // 	return (i === 0 || i === node.table.widths.length) ? 0 : 1;
        // },
        hLineColor: function (i, node) {
          return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
        },
        vLineColor: function (i, node) {
          return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
        },
        // fillColor: function (rowIndex, node, columnIndex) {
        // 	return (rowIndex % 2 === 0) ? '#CCCCCC' : null;
        // }
      },
      table: {
        // widths: printsettings['tableColumnWidth'],//need to create function which will make a array of visibal column width
        headerRows: printsettings['tableHeaderRows'],
        height: printsettings['tableHeight'],
        body: this.drawTableBody(pdfTableHeader, pdfTableData, printsettings),

        margin: [5, 2, 5, 0]
      }
    };
  };
  private drawTableBody(pdfTableHeader, pdfTableData, printsettings) {
    let body = [];
    //set Header for tables
    let headerRow = [];
    let tableRowTotal = [];
    if (printsettings['showSrNo']) {
      headerRow.push({ text: "Sr. No" });
      tableRowTotal.push({ text: "Total" });
    }
    for (let i = 0; i < pdfTableHeader.length; i++) {
      if (pdfTableHeader[i]['visiblecolm']) {
        headerRow.push({ text: pdfTableHeader[i]['display'] });
        if (printsettings['total']) {
          if (pdfTableHeader[i]['datatype'] == 'number' && pdfTableHeader[i]['total']) {
            if (i > 0 && pdfTableHeader[i - 1]['datatype'] != 'number') {
              tableRowTotal[i - 1]['text'] = "Total";
            }
            tableRowTotal.push({ text: 0 });
          } else {
            tableRowTotal.push({ text: "" });
          }
        }
      }
    }
    body = [headerRow];
    let totalCount = 0;
    const tableDataLength = pdfTableData.length;
    for (let tableDataIndex = 0; tableDataIndex < tableDataLength; tableDataIndex++) {
      let tableHeaderLength = pdfTableHeader.length;
      let tableItem = pdfTableData[tableDataIndex];
      let tableRow = [];
      for (let tableHeaderIndex = 0; tableHeaderIndex < tableHeaderLength; tableHeaderIndex++) {

        let tableHeaderItem = pdfTableHeader[tableHeaderIndex];
        if (tableHeaderItem['visiblecolm']) {// && tableItem[tableHeaderItem['key']]) {
          let total = tableRowTotal[tableHeaderIndex]['text'] ? tableRowTotal[tableHeaderIndex]['text'] : 0;

          let item: any = { text: tableItem[tableHeaderItem['key']] };
          if (printsettings['showSrNo'] && tableHeaderIndex == 0) {
            tableRow.push({ text: tableDataIndex + 1 });
          }
          if (tableHeaderItem['datatype'] == 'date') {
            item.text = this.formatDate(tableItem[tableHeaderItem['key']]);//give call to dateformat function
          }
          if (tableHeaderItem['datatype'] == 'string') {
            item.text = tableItem[tableHeaderItem['key']];
          }
          if (tableHeaderItem['datatype'] == 'number') {
            item.text = +tableItem[tableHeaderItem['key']];
            if (tableHeaderItem['total']) {
              tableRowTotal[tableHeaderIndex]['text'] = total + item.text;
              if (tableHeaderItem['decimaldigit']) {
                let n = tableRowTotal[tableHeaderIndex]['text'];
                n = +n;
                tableRowTotal[tableHeaderIndex]['text'] = +n.toFixed(+(tableHeaderItem['decimaldigit']));
              }
            }
            if (tableHeaderItem['decimaldigit']) {
              let n = tableItem[tableHeaderItem['key']];
              n = +n;
              item.text = n.toFixed(+(tableHeaderItem['decimaldigit']));
            }
          }
          if (tableHeaderItem['thstyle'] && tableHeaderItem['thstyle']['text-align']) {
            item.alignment = tableHeaderItem['thstyle']['text-align'];
            if (tableHeaderItem['total']) {
              tableRowTotal[tableHeaderIndex]['alignment'] = tableHeaderItem['thstyle']['text-align'];
            }
          }
          tableRow.push(item);
          // console.log(item);
        }
      }
      // console.log(tableRow);
      body.push(tableRow);
    }
    if (printsettings['total']) {
      body.push(tableRowTotal);
    }
    // console.log(body);
    return body;
  };

  private makePdfDocumentDefinition(pdfData) {
    let pdfHeaderdata = pdfData['printsettings']['headerdata'];
    let pdfFooterdata = pdfData['printsettings']['footerdata'];
    const pdfTableData = pdfData['pdftabledata'];
    const pdfTableHeader = pdfData['pdftableheader'];
    const printsettings = pdfData['printsettings'];
    const branchdata = pdfData['branchdata'];
    const pageData = pdfData['pagedata'];
    console.log('pageData', pageData);

    const content = pdfData['printsettings']['content'] ? pdfData['printsettings']['content'] : [];
    // let trnxmaster = pdfData['trnxdata']['trnxmaster'];
    const DocDefinition = {
      pageSize: printsettings["printpagesize"],
      // pageOrientation:printsettings['pageOrientation'],
      // watermark: { text: 'test watermark', color: 'blue', opacity: 0.3, bold: true, italics: false, angle: 70 },
      pageBreakBefore: function (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
        return currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0;
      },
      pageMargins: [30, printsettings['headermargin'] * 72, 30, printsettings['footermargin'] * 72],
      styles: printsettings['styles'],
      header: (currentPage, pageCount, pageSize) => {

        return this.drawHeader(printsettings, pdfHeaderdata, { currentPage, pageCount, pageSize })
      },

      content: [
        ...content,
        {
          columns: [
            { width: '*', text: '' },
            {
              width: 'auto',
              table: {
                widths: "auto",
                body: [[{ text: pageData["pdftitle"] ? pageData["pdftitle"] : '' }]],
                alignment: "center"
              }
            },
            { width: '*', text: '' },
          ],
          columnGap: 10,
          margin: [0, 5]
        },
        this.drawTable(pdfTableHeader, pdfTableData, printsettings)
      ],
      footer: (currentPage, pageCount, pageSize) => {
        let ftr: any = this.drawFooter(pdfFooterdata, printsettings, { currentPage, pageCount, pageSize }, branchdata);
        return ftr;

      }
    };

    return DocDefinition;
  }


}
