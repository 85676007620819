import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InstituteInfoComponent } from './landing-pages/institute-info/institute-info.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./landing-pages/landing-pages.module').then(m => m.LandingPagesModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard-pages/dashboard-pages.module').then(m => m.DashboardPagesModule)
  },
  {
    path:'admin',
    loadChildren:() => import('./admin-pages/admin-pages.module').then(m => m.AdminPagesModule)
  },
  {
    path:'institute-info',
    component: InstituteInfoComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration :'top',useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
