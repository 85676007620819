import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoursePopupIframeComponent } from './course-popup-iframe/course-popup-iframe.component';
import { CommonHeaderComponent } from './common-header/common-header.component';
import { CommonFooterComponent } from './common-footer/common-footer.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { ReadingNotesPopupsComponent } from './reading-notes-popups/reading-notes-popups.component';
import { FeedbackPopupComponent } from './feedback-popup/feedback-popup.component';
import { NotesPopupComponent } from './notes-popup/notes-popup.component';
import { DraggingDirective } from './dragging.directive';
import { DraggingHandleDirective } from './dragging-handle.directive';
import { NgbTostCustomComponent } from './ngb-tost-custom/ngb-tost-custom.component';
import { ProgramFeedbackComponent } from './program-feedback/program-feedback.component';
import { GtPlusModule } from './gt-plus/gt-plus.module';
import { RegisterPopupComponent } from './register-popup/register-popup.component';
import { QueryPopupComponent } from './query-popup/query-popup.component';

@NgModule({
  declarations: [
    CoursePopupIframeComponent,
    CommonHeaderComponent,
    CommonFooterComponent,
    ReadingNotesPopupsComponent,
    FeedbackPopupComponent,
    NotesPopupComponent,
    DraggingDirective,
    DraggingHandleDirective,
    NgbTostCustomComponent,
    ProgramFeedbackComponent,
    RegisterPopupComponent,
    QueryPopupComponent,
    
  ],
  imports: [RouterModule,NgbModule, GtPlusModule,
    FormsModule, ReactiveFormsModule, CommonModule,ModalModule.forRoot()
  ],
  exports: [CoursePopupIframeComponent,
    CommonHeaderComponent,NotesPopupComponent,
    CommonFooterComponent, GtPlusModule,
    DraggingDirective,NgbTostCustomComponent,
    DraggingHandleDirective]
})
export class SharedModule { }
