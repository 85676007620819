import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-ngb-tost-custom',
  templateUrl: './ngb-tost-custom.component.html',
  styleUrls: ['./ngb-tost-custom.component.scss'],
  host: {'class': 'toast-container position-fixed top-0 end-0 p-3', 'style': 'z-index: 1200'}
})
export class NgbTostCustomComponent implements OnInit {

  constructor(public toastService: ToastService) { }

  ngOnInit(): void {
  }
  isTemplate(toast :any) { return toast.textOrTpl instanceof TemplateRef; }

}
