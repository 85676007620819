import { Component, ViewEncapsulation } from '@angular/core';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-toastr',
  templateUrl: 'toastr.component.html',
  styleUrls: ['../../../assets/vendor/toastr/toastr.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToastrComponent {

  public toasterconfig;

  constructor(private toastService:ToastService) {
    this.toasterconfig = this.toastService.toasterconfig;
    console.log(this.toasterconfig);
  }

}
