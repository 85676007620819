import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigurationService } from "./../services/configuration.service"
import { DomSanitizer } from '@angular/platform-browser';
import { finalize, map } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { ToastService } from './toast.service';
import { ToastrService } from 'ngx-toastr';
import { EncrptDecreptService } from './encrpt-decrept.service';
@Injectable({
  providedIn: 'root'
})
export class ApiRequestsService {
  // public token: string = '';
  public baseURL = this.config.getBaseURI();
  public NO_ERROR = "000000";

  private LoaderEle: any = null;
  setCount = 0;

  constructor(private encryptService: EncrptDecreptService,
    private toastService: ToastService, private domSanitizer: DomSanitizer, private authService: AuthService,
    private config: ConfigurationService, private HttpClient: HttpClient, private toastr: ToastrService) {
    this.baseURL = this.config.getBaseURI();
    // this.token = this.authService.token;
  }

  generateHeaderForAPIRequest = (customHeaders: any = {}) => {
    return new HttpHeaders({ 'authorization': 'Bearer ' + this.authService.token, "authfor": "W", ...customHeaders });
  }
  setLoderEle() {
    this.LoaderEle = document.getElementById('loader-div');
    // console.log(this.LoaderEle);
    console.log('LoaderEle',this.LoaderEle);
    

  }

  openLoader() {
    // console.log('open loader');

    if (this.LoaderEle?.style)
      this.LoaderEle.style.display = "block";
    else {
      this.setLoderEle();
      if (this.setCount == 0) {
        this.openLoader()
        this.setCount++;
      }
    }
  }
  closeLoader() {
    // console.log('close loader');
    setTimeout(() => {

      if (this.LoaderEle?.style)
        this.LoaderEle.style.display = "none";
    }, 100);
  }

  getApiCall = (url: string, params: any = null) => {
    this.openLoader();
    let httpHeaders = this.generateHeaderForAPIRequest();
    let httpParams = new HttpParams();
    if (params)
      httpParams = httpParams.append("criteria", JSON.stringify(params));
    return this.HttpClient.get(this.baseURL + url, { params: httpParams, headers: httpHeaders }).pipe(map((data: any) => {

      if (data["errorDescription"] && (data["errorDescription"].includes('Invalid token.') || data["errorDescription"].includes('Invalid user identifier'))) {
        //     console.log("Invalid token.");
        this.authService.logout();
      }
      this.closeLoader();
      return data;
    }), finalize(() => 
      this.closeLoader()));
  }

  postApiCall = (url: string, params: any, customHeaders: any = {}, flag=true) => {
    this.openLoader();
    let httpHeaders = this.generateHeaderForAPIRequest(customHeaders);
    const criteria = {
      "criteria": {
        ...params
      }
    };
    return this.HttpClient.post(this.baseURL + url, criteria, { headers: httpHeaders }).pipe(map((data: any) => {

      if (data["errorDescription"] && data["errorDescription"].includes('Invalid token.')) {
        //     console.log("Invalid token.");
        this.authService.logout();
      }
      if(flag){
        if (data["errorCode"] == this.NO_ERROR) {
          this.toastr.success(data["errorDescription"] || 'Login', '');
        }
        else {
          this.toastr.error(data["errorDescription"] || 'error', '');
        }
      }
      this.closeLoader();
      return data;
    }), finalize(() => 
      this.closeLoader()));
  }

  updateOrSetTokenValue = (newToken: string) => {
    // this.token = newToken;
  }

  uploadFile(Image: any, id: number, endPoint: string) {


    const fd = new FormData();
    let criteria = { 'id': id };
    // fd.append('id', id);
    fd.append('criteria', JSON.stringify(criteria));
    fd.append('file', Image, Image.name);
    console.log(fd);
    console.log(Image, Image.name);
    let httpHeaders = new HttpHeaders({
      'authorization': 'Bearer ' + this.authService.token,
      "authfor": "W"
    });
    httpHeaders.set('Content-type', 'multipart/form-data; boundary=1234567890xxxyyy104');
    return this.HttpClient.post(this.baseURL + endPoint, fd, { headers: httpHeaders });
  }

  getImageSRC(defaultSrc: string, imageSrc: string = ''): any {

    if (imageSrc) {
      // this.domSanitizer.bypassSecurityTrustUrl
      //'https://viocrm1.loca.lt/uploads/VIOTALW/VIOTALW1/customer_logos/customerLogo00001.png';
      const furl: any = this.domSanitizer.bypassSecurityTrustResourceUrl(this.baseURL + imageSrc);
      //     console.log(furl);

      return furl['changingThisBreaksApplicationSecurity'];
    }
    else return defaultSrc;
  }

  downloadFilesReq(url: string, params: any = undefined) {
    this.openLoader();
    let httpparams = new HttpParams();
    if (params)
      httpparams = httpparams.append("criteria", JSON.stringify(params));
    return this.HttpClient.get(this.baseURL + url, {
      params: httpparams,
      responseType: "blob",
      headers: new HttpHeaders().append("Content-Type", "application/json")
    }).pipe(map((data: any) => {

      if (data["errorDescription"] && data["errorDescription"].includes('Invalid token.')) {
        //     console.log("Invalid token.");
        this.authService.logout();
      }
      return data;
    }), finalize(() => this.closeLoader()));;
  }

  downloadFile(name: string, downloadFileName: string = '') {
    console.log("downloadFile");

    this.openLoader();
    let params: any = { "filename": name };
    params = JSON.stringify(params);
    let data = this.encryptService.encrypt(params);
    let endpoint = 'program/downloadFile'
    this.downloadFilesReq(endpoint, { ox: data }).subscribe(successResp => {

      var blob = new Blob([successResp], { type: 'application/pdf' });
      var url = window.URL.createObjectURL(blob);
      let testDate = new Date().getTime();
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      //  a.download = this.userTests[i]["testname"]["testname"] + "_" + testDate;
      a.download = downloadFileName ? downloadFileName : "download_" + testDate;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    },
      err => {
        alert("Problem while downloading the file.");

        console.error(err);
      }
    );

  }

  getFile(apiUrl, options) {

    // return this.http.get(apiUrl,{headers: headers }
    // )

    return this.HttpClient.get(this.baseURL + apiUrl, {
      params: options,
      responseType: 'blob' // very important that this is set
    })
  }
}
