import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrentUser } from '../../interfaces/user';
import { ApiRequestsService } from '../../services/api-requests.service';
import { AuthService } from '../../services/auth/auth.service';
import { PdfService } from '../../services/pdf.service';
import { EncrptDecreptService } from '../../services/encrpt-decrept.service';

@Component({
  selector: 'app-reading-notes-popups',
  templateUrl: './reading-notes-popups.component.html',
  styleUrls: ['./reading-notes-popups.component.scss']
})
export class ReadingNotesPopupsComponent implements OnInit {

  @Input() programid: number = 0;
  @Input() sessionid: number = 0;

  @Input() isReadingOrNote: boolean = true;
  @Input() item: any;

  @Input() pname: string = "";
  @Input() sname: string = "";

  readingMatreial: any = [];
  readingMatreialObj: any = {};
  notes: any = []

  currentUser: CurrentUser;

  doenloadButtonDiss: boolean = false;

  constructor(private pdfService: PdfService, private encryptService: EncrptDecreptService,
    private authService: AuthService, private ngbActiveModal: NgbActiveModal, public api: ApiRequestsService) { }

  ngOnInit(): void {
    // console.log(this.item);
    this.currentUser = this.authService.currentUserValue?.Data;
    this.readingMatreialObj = this.item;
    if (this.programid && this.isReadingOrNote) {
      this.getReadingMaterialForProgram();
    }
    if (this.sessionid && !this.isReadingOrNote) {
      this.getUserSessionWiseNotes(this.sessionid);
    }
  }

  closeModal(isclose = true) {
    this.ngbActiveModal.close();
  }

  getReadingMaterialForProgram() {
    const endpoint: string = 'program/getReadingMaterial';
    let params: any = { programid: this.programid }
    params = JSON.stringify(params);
    params = this.encryptService.encrypt(params);
    this.api.getApiCall(endpoint, { ox: params }).subscribe((res: any) => {
      if (res['errorCode'] == this.api.NO_ERROR) {
        this.readingMatreial = [];
        this.readingMatreial = res['Data'];
        this.readingMatreialObj = this.readingMatreial[0]?.programlist[0];
        this.readingMatreial.forEach((item: any) => {
          item['programlist'].forEach((data: any, i: number) => {
            if (data['imagepath']) {
              data['imagepath'] = data['imagepath'].replace(/uploads_demo/g, "uploads")
            }
          })
        })
      }
      else { }
    }, (err) => { }, () => { })
  }


  getUserSessionWiseNotes(sessionid: number) {
    const endpoint: string = 'user/getUserSessionWiseNotes';
    let params: any = { uid: this.currentUser.uid, sessionid }
    params = JSON.stringify(params);
    params = this.encryptService.encrypt(params);

    this.api.getApiCall(endpoint, { ox: params }).subscribe((res: any) => {
      if (res['errorCode'] == this.api.NO_ERROR) {
        this.notes = [];
        this.notes = res['Data'];
      }
    }, (err) => { }, () => { })
  }


  downloadFile(name: string, downloadFileName: string) {
    // let downloadFileName = name.split('/')[1];
    this.api.downloadFile(name, downloadFileName)

  }
  downloadAll() {
    for (let rmlist of this.readingMatreialObj?.rmlist) {
      this.downloadFile(rmlist?.filepath, rmlist?.filename)
    }
  }
  downloadNotesAll() {
    this.doenloadButtonDiss = true;
    this.pdfService.generatePdf(this.pdfService.notesDefination(this.notes, this.item?.sessionname), this.item?.sessionname);
    setTimeout(() => {
      this.doenloadButtonDiss = false;
    }, 2000)
  }
}
