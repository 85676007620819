import { Injectable } from '@angular/core';
import CryptoJS from "crypto-js";

@Injectable({
  providedIn: 'root'
})
export class EncrptDecreptService {

  privateKey:string = 'viotalworkspvtltd';

  constructor() { }

  encrypt(data) {
    // Encrypt
    // const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), this.privateKey).toString();
    const ciphertext = CryptoJS.AES.encrypt(data, this.privateKey).toString();
    // console.log(ciphertext);
    return ciphertext;
  }


  decrypt(ciphertext) {
    // Decrypt
    if (!ciphertext)
      return ciphertext;
    ciphertext = ciphertext.replaceAll(/ /g, "+");
    const bytes = CryptoJS.AES.decrypt(ciphertext, this.privateKey);
    // const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return bytes.toString(CryptoJS.enc.Utf8);
  }

}
