import { Injectable } from '@angular/core';
import { ApiRequestsService } from './api-requests.service';
import { EncrptDecreptService } from './encrpt-decrept.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardApiService {

  public MyGames: any = [];

  public MyCourses: any = [];

  constructor(private encryptService: EncrptDecreptService, private api: ApiRequestsService) { }

  getScheduleSessionForSelectedUserGameOrCourses(isgame: "Y" | "N" = 'Y', callbackfun: any = undefined, thisType: any = null) {
    // if (isgame == 'N' && this.MyCourses.length) {
    //   return;
    // } else if (this.MyGames.length) {
    //   return;
    // }
    const endpoint: string = 'program/getScheduleSessionForSelectedUser';
    let params: any = { isgame }
    params = JSON.stringify(params);
    let data = this.encryptService.encrypt(params);
    this.api.getApiCall(endpoint, { ox: data }).subscribe((res: any) => {
      if (res['errorCode'] == this.api.NO_ERROR) {
        if (isgame == 'N') {
          this.MyCourses = [];
          this.MyCourses = res['Data'];
          // this.MyCourses.forEach((item)=>{
          //   item['isRecommendedCourseShow'] = "N";
          // })
          this.MyCourses.forEach((item: any) => {
            item['assignedprogramlist'].forEach((data: any, i: number) => {
              if (data['imagepath']) {
                data['imagepath'] = data['imagepath'].replace(/uploads_demo/g, "uploads");
                
              }
            })
          })
          if (callbackfun && thisType) {
            callbackfun(thisType);
          }
          // console.log("course", this.MyCourses);

        }
        else {
          this.MyGames = [];
          this.MyGames = res['Data']
          this.MyGames.forEach((item: any) => {
            item['assignedprogramlist'].forEach((data: any, i: number) => {
              if (data['imagepath']) {
                data['imagepath'] = data['imagepath'].replace(/uploads_demo/g, "uploads")
              }
            })
          })
          if (callbackfun && thisType) {
            callbackfun(thisType);
          }
          // console.log("game",this.MyGames);

        }
      }
    }, (err) => { }, () => { })
  }

  getUserAssignedProgramWiseSessionList(programid: number) {
    const endpoint: string = 'program/getUserAssignedProgramWiseSessionList';
    let params: any = { programid }
    params = JSON.stringify(params);
    let data = this.encryptService.encrypt(params);
    this.api.getApiCall(endpoint, { ox: data }).subscribe((res: any) => {
      if (res['errorCode'] == this.api.NO_ERROR) {

      }
    }, (err) => { }, () => { })
  }
}
