import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EncrptDecreptService } from '../../services/encrpt-decrept.service';
import { ApiRequestsService } from '../../services/api-requests.service';
import { HelpersService } from '../../services/helpers.service';

@Component({
  selector: 'app-query-popup',
  templateUrl: './query-popup.component.html',
  styleUrls: ['./query-popup.component.scss']
})
export class QueryPopupComponent implements OnInit {

  @Input() sessionid: number;
  @Input() item: any = {};
  @Input() programId: number;
  @Input() programname: string;


  model: any = {
    // sessionid: 0,
    sessionname:'',
    querydetails: '',
    // userid:0,
    // username:''
  };

  errorMsg: string = '';

  public isLoading = false;

  constructor(private ngbActiveModal: NgbActiveModal, private helperSer: HelpersService, private api: ApiRequestsService, private encryptService: EncrptDecreptService) { }

  ngOnInit(): void {
    this.model.sessionname = this.item.sessiondata.sessionname
    // this.model.userid = this.helperSer.userId;
    this.model.username = this.helperSer.userName;;
    this.model.sessionid = this.sessionid;
    this.model.programid= this.programId
    this.model.programname = this.programname;
  }


  closeModal(isclose = true) {
    this.ngbActiveModal.close();
  }


  insertNewUserQuery() {
    if (this.isLoading) return;
    if (!this.model.querydetails || !this.model.sessionid) {
      this.errorMsg = 'Enter Query'; return;
    }

    const endpoint: string = 'user/insertAskQuery';
    let params: any = { data: this.model };
    params = JSON.stringify(params);
    let data = this.encryptService.encrypt(params);
    

    this.isLoading = true;
    this.api.postApiCall(endpoint, { ox: data }).subscribe((res: any) => {
      if (res['errorCode'] == this.api.NO_ERROR) {
        // console.log(res);
        this.closeModal()
      }
      this.errorMsg = res['errorDescription']
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
    }, () => { })
  }


}
