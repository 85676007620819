import { Component, OnInit } from '@angular/core';
import { CurrentUser, Institution_ui_mapping } from '../../interfaces/user';
import { AuthService } from '../../services/auth/auth.service';
import { DashboardApiService } from '../../services/dashboard-api.service';
import * as AOS from 'aos';
import { HelpersService } from '../../services/helpers.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-common-footer',
  templateUrl: './common-footer.component.html',
  styleUrls: ['./common-footer.component.scss']
})
export class CommonFooterComponent implements OnInit {
  currentUser:CurrentUser;
  headerPermissionsObj:Institution_ui_mapping ;
  showStickyMenuBar = false
  copyrightYear:any;
  constructor(private route:Router, private authService: AuthService,private dashboardApiService:DashboardApiService, public helperService:HelpersService) {
    this.currentUser = this.authService.currentUserValue?.Data;
    this.headerPermissionsObj=this.currentUser?.institution_ui_mapping;
   }

  ngOnInit(): void {
    AOS.init();
    this.copyrightYear = new Date().getFullYear();
    // this.authService.currentUserSubject.subscribe((res)=>{
    //   // console.log('sub');


    // if (this.authService.token) {

    //   // this.showStickyMenuBar = false;
    // }else
    // this.showStickyMenuBar = false;},err=>{});
  }

  backToTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  goToHome(){
      this.backToTop();
      this.route.navigateByUrl("/home")
  }
}
