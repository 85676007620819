import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiRequestsService } from '../../services/api-requests.service';
import { EncrptDecreptService } from '../../services/encrpt-decrept.service';
import { ConfigurationService } from '../../services/configuration.service';
import { HelpersService } from '../../services/helpers.service';
import { AuthService } from '../../services/auth/auth.service';
import { CurrentUser } from '../../interfaces/user';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-institute-info',
  templateUrl: './institute-info.component.html',
  styleUrls: ['./institute-info.component.scss']
})
export class InstituteInfoComponent implements OnInit {

  @ViewChild('confimModal', { static: false }) public confimModal: ModalDirective;
  isTermsChecked = false;

  InstituteData: any;
  refTableName: any = '';
  loginId: any = '';
  currentUser: CurrentUser;
  instutId: any;
  errorDescription: any;
  displayMarqueeText: string = '';
  viewMarqueeText: boolean = false;
  userID:string='';
  tenant:null;
  consent:string=''
  // InstituteData : any = {
  //   instName:'ABC',
  //   wlcmMsg:'Welcome to SMBT portal',
  //   instLogo:'src/assets/logo.png',
  //   bgImg:'assets/img/slide/slide-2.jpg',
  //   // instInstruction:['Instruction 1','Instruction 2', 'Instruction 3','Instruction 4','Instruction 5']
  // }

  constructor(private ActivatedRoute: ActivatedRoute, private authService: AuthService, private helperService: HelpersService, public config: ConfigurationService, private route: Router, private encryptService: EncrptDecreptService, private apiService: ApiRequestsService) {
    this.currentUser = this.authService.currentUserValue?.Data;
  }

  ngOnInit(): void {
    this.ActivatedRoute.queryParams.subscribe((params) => {
      console.log(params);

      this.loginId = params['loginid'];
      this.instutId = params['intuitId'];
      this.userID = params['userID'];
    });
    this.getInstituteWiseUIData();

    // setTimeout(() => {
    //   this.integrationLogin()
    // }, 2000);
  }

  getInstituteWiseUIData() {

    let param: any = { intuitId: this.instutId }
    param = JSON.stringify(param);
    let data = this.encryptService.encrypt(param);
    const apiUrl = 'user/getInstituteWiseUIData'
    this.apiService.getApiCall(apiUrl, { ox: data }).subscribe((res: any) => {
      if (res['errorCode'] == this.apiService.NO_ERROR) {
        this.instutId = res['Data']['instituteid'];
        this.InstituteData = JSON.parse(res['Data']['ui_jsondata']);
        this.refTableName = res['Data']['mapping_refrance_tablename'];
        console.log(this.InstituteData['instLogo']);
        this.displayMarqueeText =  this.InstituteData['marqueeText'];
        this.viewMarqueeText =  this.InstituteData['showMarquee'];
        if (this.InstituteData['instLogo'] ) {
          this.InstituteData['instLogo'] = this.InstituteData['instLogo'].replace(/uploads_demo/g, "uploads")
        }
        if ( this.InstituteData['bgImg']) {
          this.InstituteData['bgImg'] = this.InstituteData['bgImg'].replace(/uploads_demo/g, "uploads");
          
        }

      } else {
        this.errorDescription = res['errorDescription']
      }
    })
  }


  toggleButton() {
    this.isTermsChecked = !this.isTermsChecked;
  }
  openPopup(){
    this.confimModal.show();
  }

  // if (data['imagepath']) {
  //   data['imagepath'] = data['imagepath'].replace(/uploads_demo/g, "uploads")
  // }

  integrationLogin() {

    let param: any = { uid: this.loginId, intuitId: this.instutId, reftable: this.refTableName }
    param = JSON.stringify(param);
    let data = this.encryptService.encrypt(param);
    const apiUrl = 'user/integrationLogin'
    this.apiService.postApiCall(apiUrl, { ox: data, dType: "W" }).subscribe((res: any) => {

      if (res.errorCode == "000000" && res.Data.token) {
        this.helperService.storeResponseData = res;
        this.helperService.userType = res?.Data?.ut;
        this.authService.token = res.Data.token;
        this.helperService.userId = res?.Data?.uid;
        this.helperService.userName = res?.Data?.uname;
        this.consent = res?.Data?.concent;
        this.authService._syncUser(res)

        this.helperService.type = res?.Data?.type;
        const storgeData = JSON.stringify(res);
        sessionStorage.setItem("qwerty", storgeData);
        sessionStorage.setItem("institute", param);
        // localStorage.setItem('qwerty', storgeData);

        if(this.consent == "N"){
          this.openPopup();
        }
        else if (res.Data.registerfor == "T") {
          window.location.href = location.origin + '/testcenter';
          return;
        } else if (this.helperService.LoginFrom == "cart") {
          this.route.navigateByUrl('/add-to-cart');
        } else if (res?.Data?.ut == 'SA' || res?.Data?.ut == 'IA' || res?.Data?.ut == 'CA') {
          this.route.navigateByUrl('/dashboard/user-list');
        } else {
          this.route.navigateByUrl('/dashboard/my-courses');
        }
        
      }
      // if (res['errorCode'] == this.apiService.NO_ERROR) {
      //   // this.InstituteData = res['Data'];
      //   console.log(res['Data']);
      //   const storgeData = param;
      //   sessionStorage.setItem("institute", storgeData);
      //   this.route.navigateByUrl('/dashboard/my-courses');


      // }else{
      // const storgeData = param;
      // sessionStorage.setItem("institute", storgeData);

      // this.route.navigateByUrl('/dashboard/my-courses');
      // const storgeData = param;
      // sessionStorage.setItem("institute", storgeData);
      // let data = sessionStorage.getItem("institute");
      // let val = JSON.parse(data)
      // console.log('val',val.intuitId);
      // }
    })
  }


  userProceed(){
    let param: any = { userID: this.userID, tenant: this.tenant, concent:this.consent }
    param = JSON.stringify(param);
    let data = this.encryptService.encrypt(param);
    const apiUrl = 'user/saveUserConcent'
    this.apiService.postApiCall(apiUrl, { concent:"Y",tenant:null,userID:this.userID}).subscribe((res: any) => {
        
        this.route.navigateByUrl('/dashboard/my-courses');
      
    })
  }

  // displayMarquee(){
     
  // }

}
