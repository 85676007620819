import { EventEmitter, Injectable } from '@angular/core';
import { Programlist, ProgramVerticals } from '../interfaces/user';

function _window(): any {
  // return the global native browser window object
  return window;
}


@Injectable({
  providedIn: 'root'
})
export class HelpersService {

  cartData = new EventEmitter<any | []>();

  public storeResponseData: any;
  public userType: string = "";
  public type: string = "";
  public LoginFrom: string = "";
  public cartDataCount: number = 0;
  public userId:number = 0;
  public userName:string='';
  addToCartArrayList: any[] = [];


  public checkedData:any;
  public validFrom:any;
  public validTill:any;

  public addToCartArrayListBackup: any[] = [];

  constructor() { }

  get nativeWindow(): any {
    return _window();
  }

  removeRefrence(data: any) {
    return JSON.parse(JSON.stringify(data));
  }

  showAllPrograms(programVerticals: ProgramVerticals[]) {
    const programList: Programlist[] = [];

    programVerticals.forEach((ele: ProgramVerticals) => {
      ele?.assignedprogramlist.forEach((obj: Programlist) => {
        obj.vName = ele?.displayname;
        obj.panelbackcolor = ele?.panelbackcolor
        programList.push(obj);
      });

    });
    return programList;
  }

  replaceUrlForImgs(data: any) {
    let imgPath = "";
    data.forEach((data: any, i: number) => {
      if (data['imagepath']) {
        data['imagepath'] = data['imagepath'].replace(/uploads_demo/g, "uploads")
        imgPath = data['imagepath'];
      }
    })
    return imgPath;
  }

  addToCart(data) {
    this.addToCartArrayList.push(data);
    let storeData = JSON.stringify(this.addToCartArrayList);
    localStorage.setItem("cartitems", storeData)
    // this.isItemAlreadyInCart()

    this.cartData.emit(JSON.parse(storeData))
  }

  restrictCharacters(str) {
    const invalidChars = ["<", ">", "~", "\\", "`", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "-", "_", "=", "+", "{", "}", "[", "]", ";", ":", "'", '"', "<", ">", ",", ".", "/", "?", "\\", "|"];

    return invalidChars.includes(str);
  }

}
