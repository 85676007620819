import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


// Modal Component
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SharedModule } from './shared/shared.module';

import { TokenInterceptor } from './services/auth/token.interceptor';
import { ErrorInterceptor } from './services/auth/error.interceptor';
import { AuthService } from './services/auth/auth.service';
import { GtPlusModule } from './shared/gt-plus/gt-plus.module';
import { ToastService } from './services/toast.service';
import { ToasterModule, ToasterService } from 'angular2-toaster';
import { ToastrComponent } from './view/toastr/toastr.component';
import { CustomEvents } from './shared/gt-plus/events';
import { ToastrModule } from 'ngx-toastr';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';


@NgModule({
  declarations: [
    AppComponent,
    ToastrComponent,

  ],
  imports: [
    HttpClientModule, ToasterModule,
    BrowserModule, SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),NgbModule,
    BsDatepickerModule.forRoot(),
  ],
  providers: [
    AuthService,
    ToasterService,
    CustomEvents,
    ToastrComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
